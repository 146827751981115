<template>
  <div v-swiper:Gallery="LogoGallery" class="absolute h-screen w-screen" style="overflow:inherit;">
    <div class="swiper-wrapper">
        <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogoGallery',
  data () {
    return {
      LogoGallery: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop:true,
        autoplay: {
          delay: 5000,
        },
        preventClicks: true,
      }
    }
  }
}
</script>
