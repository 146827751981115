<template>
  <div v-swiper:Gallery="LogoGallery" style="overflow:inherit;">
    <div class="swiper-wrapper">
        <slot />
    </div>
    <div class="relative text-center -top-12  gallery-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'LogoGallery',
  data () {
    return {
      LogoGallery: {
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
        },
        preventClicks: false,
        pagination: {
          clickable: true,
          el: '.swiper-pagination',
        },
      }
    }
  }
}
</script>
