<template>
  <div>
    <div class="w-full justify-between font-lato text-15 border-t-2 py-2 mt-4 flex items-center cursor-pointer" @click="expanded = !expanded" style="border-color: rgba(255,255,255,0.3);">
      <span class="text-white">{{ title }}</span>
        <svg :class="{rotated: expanded}" xmlns="http://www.w3.org/2000/svg" width="20" height="9" viewBox="0 0 22.011 11.713">
          <path id="Path_631" data-name="Path 631" d="M0,20.6,10.3,10.3,0,0" transform="translate(21.304 0.707) rotate(90)" fill="none" stroke="#ba9765" stroke-linecap="round" stroke-width="1"/>
        </svg>
    </div>
      <div class="accordion " :class="{expanded: expanded}":style="[expanded ? { height : computedHeight } : {}]">
        <div class="prose">
          <slot />
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'red'
    }
  },
  data () {
    return {
      computedHeight: 0,
      expanded: false
    }
  },
  mounted: function(){
    this.initHeight()
  },
  computed: {
    icon () {
      if (this.color === 'green') {
        return 'text-green-500'
      }else if (this.color === 'purple') {
        return 'text-purple-500'
      }else if (this.color === 'teal') {
        return 'text-teal-500'
      }else {
        return 'text-green-400'
      }
    }
  },
  methods: {
    initHeight: function(){
      // this.$refs['accordion'].style.height = 'auto'
      // this.$refs['accordion'].style.visibility = 'hidden'
      // this.$refs['accordion'].style.display = 'block'
      // const height = getComputedStyle(this.$refs['accordion']).height
      // this.computedHeight= height
      // this.$refs['accordion'].style.position = null
      // this.$refs['accordion'].style.visibility = null
      // this.$refs['accordion'].style.display = null
      // this.$refs['accordion'].style.height = 0
    }
  }
}
</script>
<style lang="scss">
.rotated{
  transform: rotate(180deg);
}
.accordion{
  transition: height .25s ease, opacity .25s ease;
  height: 0;
  opacity: 0;
  overflow: hidden;
  &.expanded{
    opacity: 1;
  }
}
</style>
