<template>
  <div class="accordion-item">

    <div
      @click="toggleAccordion()"
      class="heading flex items-center justify-between py-4 border-t"
      style="border-color: rgba(255,255,255,0.3);"
      :aria-expanded="isOpen"
      :class="{bggray: isOpen}"
      :aria-controls="`collapse${_uid}`"
    >
      <span class="text-white font-lato text-15"><slot name="title"/></span>

      <div class="pr-4 pt-3 md:pt-0">
        <svg :class="{rotated: isOpen}" xmlns="http://www.w3.org/2000/svg" width="20" height="9" viewBox="0 0 22.011 11.713">
          <path id="Path_631" data-name="Path 631" d="M0,20.6,10.3,10.3,0,0" transform="translate(21.304 0.707) rotate(90)" fill="none" stroke="#ba9765" stroke-linecap="round" stroke-width="1"/>
        </svg>
      </div>
    </div>

    <transition name="slide">
    <div v-if="isOpen" :class="{bggray: isOpen}" :id="`collapse${_uid}`" class="content">
        <slot name="content"/>
    </div>

    </transition>
  </div>
</template>

<script>
export default {
  name: 'AccordionReg',
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.rotated{
  transform: rotate(180deg);
}
.bggray{
  background:#1F1F1F;
}
</style>
