<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 border-2 text-white border-white bg-black font-lato p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-bold">Thank You!</p>
          <p class="text-sm">{{ success }}</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white font-lato bg-black p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-bold">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form  name="contact-form" class="grid gap-x-6 gap-y-8 md:grid-cols-4" @submit.stop>
      <validation-provider v-slot="{ errors }" name="Name" class="md:col-span-2">
        <div>
          <input
            id="form-name"
            v-model="form.name"
            :class="{ error: errors[0] }"
            type="text"
            class="form-input rounded-none input focus:border-grey-500 focus-visible:border-grey-500"
            placeholder="Name *"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email" class="md:col-span-2">
        <div>
          <input
            id="form-email"
            v-model="form.email"
            :class="{ error: errors[0] }"
            type="text"
            class="form-input rounded-none input text-black focus:border-grey-500 focus-visible:border-grey-500"
            placeholder="Email address *"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <!-- <validation-provider v-slot="{ errors }" name="Surname" class="md:col-span-2">
        <div>
          <select
          class="form-input rounded-none text-gray-400 input focus:border-grey-500 focus-visible:border-grey-500"
          name="Surname"

          id="form-subject"
          :class="{ error: errors[0] }"
          >
          <option value="" selected disabled>Service</option>
          <option value="Site Approval">Site Approval</option>
          <option value="Concept Design">Concept Design</option>
          <option value="Design Planning">Design Planning</option>
          <option value="Design & Building Regulation">Design & Building Regulation</option>
          <option value="Construction Information">Construction Information</option>
          <option value="Tender Process">Tender Process</option>
          <option value="Developers Design">Developers Design</option>
          <option value="Construction & Site Work">Construction & Site Work</option>
        </select>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider> -->
      <!-- <validation-provider v-slot="{ errors }" name="Region" class="md:col-span-2">
        <div>
          <select
          class="form-input rounded-none text-gray-400 input focus:border-grey-500 focus-visible:border-grey-500"
          name="region"

          id="form-subject"
          :class="{ error: errors[0] }"
          >
          <option selected disabled>Sector</option>
          <option value="Retail">Retail</option>
          <option value="Commercial">Commercial</option>
          <option value="Education">Education</option>
          <option value="Residential">Residential</option>
          <option value="Community">Community</option>
        </select>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider> -->
      <validation-provider v-slot="{ errors }" name="Message" class="md:col-span-4">
        <div>
          <textarea
            id="form-message"
            v-model="form.message"
            :class="{ error: errors[0] }"
            class="form-textarea textarea focus:border-grey-500 focus-visible:border-grey-500"
            rows="4"
            placeholder="Your Message *"
          ></textarea>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <div class="md:col-span-4">
        <button type="button" :disabled="invalid" class="text-primary border uppercase font-lato font-bold border-primary py-1 px-6 mb-12 hover:bg-primary hover:text-white transition ease-in-out duration-300" @click="passes(SendMessage)">
           Send
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      form: {}
    }
  },
  methods: {
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
        .then((response) => {
          this.form = {} // Clear input fields.
          this.$nextTick(() => this.$refs.form.reset())
          this.success = response.data.message
          this.error = false
          /*
          this.$notify({
            type: 'primary',
            showMethod: 'fade in right',
            message: response.data.message
          })*/
        })
        .catch((err) => {
          this.success = false
          this.error = err.response.data.message
          /*
          this.$notify({
            message: err.response.data.message
          })
          */
        })
        .then(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
  input:active{
    border:none!important;
  }
  input:focus-visible{
    outline-width: 0px;
  }
  textarea:focus-visible{
    outline-width: 0px;
  }
</style>
