<template>
  <div v-swiper:Gallery="LogoGallery" style="overflow:inherit;">
    <div class="swiper-wrapper">
        <slot />
    </div>
    <div class="relative text-center font-lato -top-6 swipe-pagination"></div>
    <div class="flex justify-center absolute bottom-4 w-full gap-x-32 lg:gap-x-64">
  <div class="swiper-button-prevs z-10">
    <svg id="Component_15_24" data-name="Component 15 – 24" xmlns="http://www.w3.org/2000/svg" fill="none" class="transform rotate-180" width="40" height="40" viewBox="0 0 53 53">
        <path d="M26.5,0A26.5,26.5,0,1,1,0,26.5,26.5,26.5,0,0,1,26.5,0Z" stroke="none"/>
        <path d="M 26.5 1 C 23.05707931518555 1 19.71768188476562 1.674079895019531 16.57455825805664 3.003509521484375 C 13.53815841674805 4.287799835205078 10.81098175048828 6.126579284667969 8.468780517578125 8.468780517578125 C 6.126579284667969 10.81098175048828 4.287799835205078 13.53815841674805 3.003509521484375 16.57455825805664 C 1.674079895019531 19.71768188476562 1 23.05707931518555 1 26.5 C 1 29.94292068481445 1.674079895019531 33.28231811523438 3.003509521484375 36.42543792724609 C 4.287799835205078 39.46183776855469 6.126579284667969 42.18901824951172 8.468780517578125 44.53121948242188 C 10.81098175048828 46.87342071533203 13.53815841674805 48.71220016479492 16.57455825805664 49.99649047851562 C 19.71768188476562 51.32592010498047 23.05707931518555 52 26.5 52 C 29.94292068481445 52 33.28231811523438 51.32592010498047 36.42543792724609 49.99649047851562 C 39.46183776855469 48.71220016479492 42.18901824951172 46.87342071533203 44.53121948242188 44.53121948242188 C 46.87342071533203 42.18901824951172 48.71220016479492 39.46183776855469 49.99649047851562 36.42543792724609 C 51.32592010498047 33.28231811523438 52 29.94292068481445 52 26.5 C 52 23.05707931518555 51.32592010498047 19.71768188476562 49.99649047851562 16.57455825805664 C 48.71220016479492 13.53815841674805 46.87342071533203 10.81098175048828 44.53121948242188 8.468780517578125 C 42.18901824951172 6.126579284667969 39.46183776855469 4.287799835205078 36.42543792724609 3.003509521484375 C 33.28231811523438 1.674079895019531 29.94292068481445 1 26.5 1 M 26.5 0 C 41.13554000854492 0 53 11.86445999145508 53 26.5 C 53 41.13554000854492 41.13554000854492 53 26.5 53 C 11.86445999145508 53 0 41.13554000854492 0 26.5 C 0 11.86445999145508 11.86445999145508 0 26.5 0 Z" stroke="none" fill="#fff"/>
      <path id="Path_66" data-name="Path 66" d="M12484.853,3904.911l9.85-9.849-9.85-9.85" transform="translate(-12461.437 -3869.215)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
    </svg>


  </div>
  <div class="swiper-button-nexts z-10">
    <svg id="Component_15_24" data-name="Component 15 – 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 53 53">
        <path d="M26.5,0A26.5,26.5,0,1,1,0,26.5,26.5,26.5,0,0,1,26.5,0Z" stroke="none"/>
        <path d="M 26.5 1 C 23.05707931518555 1 19.71768188476562 1.674079895019531 16.57455825805664 3.003509521484375 C 13.53815841674805 4.287799835205078 10.81098175048828 6.126579284667969 8.468780517578125 8.468780517578125 C 6.126579284667969 10.81098175048828 4.287799835205078 13.53815841674805 3.003509521484375 16.57455825805664 C 1.674079895019531 19.71768188476562 1 23.05707931518555 1 26.5 C 1 29.94292068481445 1.674079895019531 33.28231811523438 3.003509521484375 36.42543792724609 C 4.287799835205078 39.46183776855469 6.126579284667969 42.18901824951172 8.468780517578125 44.53121948242188 C 10.81098175048828 46.87342071533203 13.53815841674805 48.71220016479492 16.57455825805664 49.99649047851562 C 19.71768188476562 51.32592010498047 23.05707931518555 52 26.5 52 C 29.94292068481445 52 33.28231811523438 51.32592010498047 36.42543792724609 49.99649047851562 C 39.46183776855469 48.71220016479492 42.18901824951172 46.87342071533203 44.53121948242188 44.53121948242188 C 46.87342071533203 42.18901824951172 48.71220016479492 39.46183776855469 49.99649047851562 36.42543792724609 C 51.32592010498047 33.28231811523438 52 29.94292068481445 52 26.5 C 52 23.05707931518555 51.32592010498047 19.71768188476562 49.99649047851562 16.57455825805664 C 48.71220016479492 13.53815841674805 46.87342071533203 10.81098175048828 44.53121948242188 8.468780517578125 C 42.18901824951172 6.126579284667969 39.46183776855469 4.287799835205078 36.42543792724609 3.003509521484375 C 33.28231811523438 1.674079895019531 29.94292068481445 1 26.5 1 M 26.5 0 C 41.13554000854492 0 53 11.86445999145508 53 26.5 C 53 41.13554000854492 41.13554000854492 53 26.5 53 C 11.86445999145508 53 0 41.13554000854492 0 26.5 C 0 11.86445999145508 11.86445999145508 0 26.5 0 Z" stroke="none" fill="#fff"/>
      <path id="Path_66" data-name="Path 66" d="M12484.853,3904.911l9.85-9.849-9.85-9.85" transform="translate(-12461.437 -3869.215)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
    </svg>


  </div>
</div>
  </div>
</template>
<script>
export default {
  name: 'LogoGallery',
  data () {
    return {
      LogoGallery: {
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
        },
        navigation: {
         nextEl: '.swiper-button-nexts',
         prevEl: '.swiper-button-prevs',
        },
        preventClicks: false,
        pagination: {
          clickable: true,
          el: '.swipe-pagination',
          type: 'fraction',
        },
      }
    }
  }
}
</script>
