<template>
  <div>
    <div class="flex mt-8">
      <button id="support" class="inline-block border-col w-80 border-b text-primary text-xl italic pb-2 hover:cursor-pointer" :class="{'border-added': selected === 1}" @click="selecttab(1)">C.M.A. Standardised Price List</button>
      <button id="registar" class="inline-block border-col w-80 border-b text-primary text-xl italic pb-2 hover:cursor-pointer" :class="{'border-added': selected === 2}" @click="selecttab(2)">Our Bespoke Price List</button>
    </div>
    <div v-show="selected===1">
      <slot name="priceone" />
    </div>
    <div v-show="selected===2">
      <slot name="pricetwo" />
    </div>
  </div>
</template>
<script>
export default {
data () {
  return {
    selected: 1,
  }
},
methods: {
  selecttab(tab){
    this.selected = tab
  }
}
}
</script>
<style lang="scss">
  .border-added{
    border-bottom:2px solid #BA9765!important;
  }
  .border-col{
    border-color: rgba(255,255,255,0.3);
  }
</style>
