<template>
  <div class="accordion-item transition-all ease-in-out duration-300">

    <div
      @click="toggleAccordion();"
      class="heading flex items-center pl-1 justify-between relative py-4 border-t transition-all ease-in-out duration-300"
      style="border-color: rgba(255,255,255,0.3);"
      :aria-expanded="isOpen"
      :class="{bggray: isOpen}"
      :aria-controls="`collapse${_uid}`"
    >
      <span class="text-white font-lato text-15"><slot name="title"/></span>

      <div class="pr-4 pt-3 md:pt-0">
        <svg :class="{rotated: isOpen}" xmlns="http://www.w3.org/2000/svg" width="20" height="9" viewBox="0 0 22.011 11.713">
          <path id="Path_631" data-name="Path 631" d="M0,20.6,10.3,10.3,0,0" transform="translate(21.304 0.707) rotate(90)" fill="none" stroke="#ba9765" stroke-linecap="round" stroke-width="1"/>
        </svg>
      </div>
    </div>
    <div ref="dropdown" :class="{bggray: isOpen}" :style="[isOpen ? { height : computedHeight } : {}]" class="px-3 overflow-hidden content contentnew">
        <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionReg',
  data() {
    return {
      computedHeight: 0,
      isOpen: false,
    };
  },
  mounted: function(){
    this.initHeight()
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    initHeight: function(){
      this.$refs['dropdown'].style.height = 'auto'
      this.$refs['dropdown'].style.visibility = 'hidden'
      this.$refs['dropdown'].style.display = 'block'
      const height = getComputedStyle(this.$refs['dropdown']).height
      this.computedHeight= height
      this.$refs['dropdown'].style.position = null
      this.$refs['dropdown'].style.visibility = null
      this.$refs['dropdown'].style.display = null
      this.$refs['dropdown'].style.height = 0
    },
  },
};
</script>

<style lang="scss">
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.rotated{
  transform: rotate(180deg);
}
.content{
  transition: height .25s ease, opacity .25s ease;
  height: 0;
}
.bggray{
  background:#1F1F1F;
  padding-bottom:1rem;
  transition: ease-in-out all 0.5s;
}
</style>
